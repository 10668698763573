import React from "react"
import {Link, withPrefix} from "gatsby"
import Layout from "@layout/layout"
import WhiteArrow from "@svg/white-arrow.js"
import WhiteHeart from "@svg/white-heart.js"
import {
  pl,
  en,
  hero,
  founders,
  council_title,
  media_title,
  volunteers_title,
} from "@content/pages/about-us.yaml"
import HeaderSubpage from "@layout/header-subpage"
import PopupPerson from "@layout/popup-person"
import {RenderTimes} from "../../utils/rendering"
import SEO from "@layout/seo"
import Helmet from "react-helmet";

export default ({data, isEn}) => {
  const people = data.allPeopleYaml.edges.map(({node}) => node)
  const council = people.filter((person) => person.type === 'council')
  const volunteers = people.filter((person) => person.type === 'volunteer')

  const translation = isEn ? en : pl

  return (
    <Layout isEn={isEn}>
      <SEO
        isEn={isEn}
        title={translation.metaTitle}
        description={translation.metaDescription}
      />
      <HeaderSubpage isEn={isEn}/>
      <section className="section wrapper">
        <section
          className="padding wow fadeInUp"
          data-wow-duration="1.5s"
          style={{
            visibility: "hidden",
          }}
        >
          <div className="row">
            <div className="large-12 medium-12 small-12 columns text-center">
              <h1 className="o-nas-odstep">{translation.title}</h1>
            </div>
          </div>
          <div className="row">
            <div className="large-10 large-centered medium-12 small-12 columns">
              <div
                className="page-cover page-cover-mobile"
                style={{
                  backgroundImage: `url(${withPrefix(hero.image.url)})`,
                }}
              ></div>
            </div>
          </div>
        </section>

        <section
          className="news-text padding wow fadeInUp"
          data-wow-duration="1.5s"
          style={{
            visibility: "hidden",
          }}
        >
          <div
            className="row"
            data-equalizer="photo1"
            data-resize="photo1"
            data-mutate="photo1"
            data-events="mutate"
          >
            {!isEn && (
              <div className="large-12 medium-12 small-12 columns margin-bottom40">
                <p>{translation.description}</p>
              </div>
            )}
            <div className="large-5 medium-12 small-12 columns text-center">
              <div
                className="display-table small-block"
                data-equalizer-watch="photo1"
              >
                <div className="display-table-cell">
                  <p>
                    <img
                      src={withPrefix(founders.image.url)}
                      alt="zarząd SASA pomoc humanitarna organizacje"
                    />
                  </p>
                </div>
              </div>
            </div>
            <div className="large-7 medium-12 small-12 columns">
              <div
                className="display-table small-block"
                data-equalizer-watch="photo1"
              >
                <div className="display-table-cell">
                  <h2 className="o-nas-odstep">{translation.founders_title}</h2>
                  <p></p>
                  <p>{translation.founders_description}</p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {!isEn && (
          <section
            className="padding wow fadeInUp"
            data-wow-duration="1.5s"
            style={{
              visibility: "hidden",
            }}
          >
            <div className="row">
              <div className="large-12 medium-12 small-12 columns">
                <h2 className="o-nas-odstep2">{council_title}</h2>
              </div>

              <div className="large-12 medium-12 small-12 columns">
                <div className="patron-slider">
                  {council.map((member, key) => (
                    <div className="gallery-cell" key={key}>
                      <div className="patron">
                        <div className="inner margin-bottom10">
                          <a
                            href="#"
                            data-borderpopup={`borderpopup-${"council-" + key}`}
                            className="all open-borderpopup"
                          ></a>
                          <div
                            className="image"
                            style={{
                              backgroundImage: `url(${withPrefix(
                                member.image.url
                              )})`,
                            }}
                          ></div>
                        </div>
                        <p className="big margin-0">{member.title}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )}
        {!isEn && (
          <section
            className="padding wow fadeInUp"
            data-wow-duration="1.5s"
            style={{
              visibility: "hidden",
            }}
          >
            <div className="row">
              <div className="large-12 medium-12 small-12 columns">
                <h2 className="o-nas-odstep2">{volunteers_title}</h2>
              </div>

              <div className="large-12 medium-12 small-12 columns show-for-large">
                <div className="helpers-slider" id="helpers-slider-large">
                  {RenderTimes(Math.ceil(volunteers.length / 6), index => (
                    <div className="gallery-cell" key={index}>
                      <div className="helper">
                        <div className="row">
                          {volunteers
                            .slice(index * 6, (index + 1) * 6)
                            .map((member, key) => (
                              <div className="large-4 medium-6 small-12 columns text-center medium-text-left" key={key}>
                                <article className="news-small margin-bottom10 aboutus">
                                  <a
                                    href="#"
                                    data-borderpopup={`borderpopup-${
                                      "volunteer-" + (key + index * 6)
                                    }`}
                                    className="open-borderpopup inner display-table "
                                  >
                                    <div className="inner-content display-table-cell"></div>
                                  </a>
                                  <div
                                    className="image small_scale"
                                    style={{
                                      backgroundImage: `url(${withPrefix(
                                        member.image.url
                                      )})`,
                                    }}
                                  ></div>
                                </article>
                                <p className="big">{member.title}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="large-12 medium-12 small-12 columns hide-for-large">
                <div className="helpers-slider" id="helpers-slider-small">
                  {volunteers.map((member, key) => (
                    <div className="gallery-cell" key={key}>
                      <div className="helper">
                        <div className="row">
                          <div className="medium-12 small-12 columns text-center medium-text-left">
                            <article className="news-small margin-bottom10 aboutus">
                              <a
                                href="#"
                                data-borderpopup={`borderpopup-${
                                  "volunteer-" + key
                                }`}
                                className="open-borderpopup inner display-table "
                              >
                                <div className="inner-content display-table-cell"></div>
                              </a>
                              <div
                                className="image small_scale"
                                style={{
                                  backgroundImage: `url(${withPrefix(
                                    member.image.url
                                  )})`,
                                }}
                              ></div>
                            </article>
                            <p className="big">{member.title}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        )}
        {!isEn && (
          <section
            className="padding wow fadeInUp"
            data-wow-duration="1.5s"
            style={{
              visibility: "hidden",
            }}
          >
            <div className="row">
              <div className="large-12 medium-12 small-12 columns text-center">
                <h2 className="o-nas-odstep">{media_title}</h2>
                <div className="quotes">
                  <div className="inner text-center">
                    <div className="quotes-slider">
                      <div
                        className="gallery-cell"
                        data-equalizer="quoteheight"
                      >
                        <div
                          className="quote display-table"
                          data-equalizer-watch="quoteheight"
                        >
                          <div className="display-table-cell">
                            <p>
                              <a
                                className="red"
                                href="http://www.wysokieobcasy.pl/wysokie-obcasy/1,80530,19319363,hipokrates-w-tanzanii-przy-amputacji-nogi-dwoch-chirurgow.html"
                                target="_blank"
                              >
                                <p>
                                  Hipokrates w Tanzanii - Wysokie Obcasy
                                  14.12.2015
                                </p>
                              </a>
                            </p>
                            <div className="source">
                              <a
                                href="http://www.wysokieobcasy.pl/wysokie-obcasy/1,80530,19319363,hipokrates-w-tanzanii-przy-amputacji-nogi-dwoch-chirurgow.html"
                                target="_blank"
                              >
                                http://www.wysokieobcasy.pl/wysokie-obcasy/1,80530,19319363,hipokrates-w-tanzanii-przy-amputacji-nogi-dwoch-chirurgow.html
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gallery-cell"
                        data-equalizer="quoteheight"
                      >
                        <div
                          className="quote display-table"
                          data-equalizer-watch="quoteheight"
                        >
                          <div className="display-table-cell">
                            <p>
                              <a
                                className="red"
                                href="https://www.wprost.pl/516082/Przyszli-lekarze-na-pomoc-swiatu"
                                target="_blank"
                              >
                                <p>
                                  Przyszli lekarze na pomoc światu - Wprost
                                  30.07.2015
                                </p>
                              </a>
                            </p>
                            <div className="source">
                              <a
                                href="https://www.wprost.pl/516082/Przyszli-lekarze-na-pomoc-swiatu"
                                target="_blank"
                              >
                                https://www.wprost.pl/516082/Przyszli-lekarze-na-pomoc-swiatu
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="gallery-cell"
                        data-equalizer="quoteheight"
                      >
                        <div
                          className="quote display-table"
                          data-equalizer-watch="quoteheight"
                        >
                          <div className="display-table-cell">
                            <p>
                              <a
                                className="red"
                                href="http://www.bedacmlodymlekarzem.pl/2016/08/biale-niedziele/"
                                target="_blank"
                              >
                                <p>Białe Niedziele - BML 19.08.2016</p>
                              </a>
                            </p>
                            <div className="source">
                              <a
                                href="http://www.bedacmlodymlekarzem.pl/2016/08/biale-niedziele/"
                                target="_blank"
                              >
                                http://www.bedacmlodymlekarzem.pl/2016/08/biale-niedziele/
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        <section
          className="padding wow fadeInUp"
          data-wow-duration="1.5s"
          style={{visibility: "hidden"}}
        >
          <div className="row">
            <div className="large-12 medium-12 small-12 columns text-center">
              <a
                data-borderpopup="borderpopup-kontakt"
                className="rollover red open-borderpopup"
              >
                <span className="underlined">
                  <span className="roll-icon">
                    <WhiteArrow/>
                    <WhiteHeart/>
                  </span>
                </span>
                <h4 className="white big margin-bottom10">
                  {translation.join_title}
                </h4>
                <p className="roll-show"></p>
              </a>
            </div>
          </div>
        </section>
      </section>
      {council.map((member, key) => (
        <PopupPerson person={member} key={key} id={"council-" + key}/>
      ))}
      {volunteers.map((member, key) => (
        <PopupPerson person={member} key={key} id={"volunteer-" + key}/>
      ))}
    </Layout>
  )
}

export const result = graphql`
    query EnPeople {
        allPeopleYaml {
            edges {
                node {
                    title
                    image {
                        url
                    }
                    person_function
                    description
                    type
                }
            }
        }
    }
`
