import React from "react"
import { withPrefix } from "gatsby"

const PopupPerson = ({ id, person }) => {
  return (
    <section id={`borderpopup-${id}`} className="borderpopup redbg">
      <div className="inner">
        <span className="l1"></span>
        <span className="l2"></span>
        <span className="l3"></span>
        <span className="l4"></span>
        <div className="mobilemenu_btn_content borderpopup_close">
          <div className="mobilemenu_btn open">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <small>zamknij</small>
        </div>
        <div className="borderpopup_content">
          <div className="row expanded collapse">
            <div className="large-6 medium-12 small-12 columns">
              <div
                className="photobg"
                style={{
                  backgroundImage: `url(${withPrefix(person.image.url)})`,
                }}
              >
                &nbsp;
              </div>
            </div>
            <div className="large-6 medium-12 small-12 columns text-left">
              <div className="borderpopup_text white_text">
                <div className="borderpopup_text_inner news-text small-text">
                  <h2>{person.title}</h2>
                  <p
                    dangerouslySetInnerHTML={{ __html: person.person_function }}
                  />
                  <p>{person.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PopupPerson
